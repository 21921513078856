import React from "react"
import { Link } from "gatsby"
import Layout from "../components/Layout"
import { ImageDiv } from "../styles/ProjectStyles"

const votingApp = () => {
  return (
    <Layout>
      <section style={{ position: "relative" }}></section>
      <div
        style={{
          display: "flex",
          flexFlow: "row",
          backgroundColor: "#1d242a",
        }}
      >
        <ImageDiv flex pt img={"/voting-app.png"} />
        <ImageDiv flex pt img={"/voting01.png"} />
        <ImageDiv flex pt img={"/voting02.png"} />
        <ImageDiv flex pt img={"/voting03.png"} />
      </div>
      <div
        style={{
          backgroundColor: "#1d242a",
          width: "100%",
          height: "300px",
          justifyContent: "center",
          alignItems: "center",
          padding: "70px",
        }}
      >
        <p
          style={{
            color: "white",
            fontFamily: "Inconsolata",
            fontSize: "25px",
            margin: "50px",
            textAlign: "left",
            width: "70%",
          }}
        >
          <h2 style={{ margin: "0" }}>Voting - the agony of choice: </h2> As
          part of my bachelor thesis, I developed a native app that allows users
          to securely and anonymously participate in polls and secure elections.
          I developed the app mainly with React Native and Expo and learned a
          lot about the basics of different frameworks. As a database I used
          FireBase. A first version of the app can be viewed{" "}
          <Link
            style={{ color: "#e91150" }}
            to="https://expo.io/@huckepack/choice-app"
          >
            HERE
          </Link>
        </p>
      </div>
    </Layout>
  )
}

export default votingApp
